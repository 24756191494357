import React, { useEffect, useState } from "react";
import dataServices from "../../apiServices/data.services";
import { handleValidationError } from "../../components/CommonFunctions";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import Loader from "../../components/Loader/Loader";

const Payments = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const alert = useAlert();

  const getPayments = () => {
    setLoading(true);
    dataServices
      .getReports(null, null,null,id)
      .then((res) => {
        if (res.status === 200) {
          setPayments(res.data);
        }
      })
      .catch((err) => {
        alert.error(handleValidationError(err));
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPayments();
  }, [id]);
  return (
    <>
    <div className="list">
     {payments ? (
      payments.map((payment) => (
        <div key={payment.id} className="mb-4">
          <Link to={"/invoice-details/" + payment.id}>
            {payment.payment_date}
          </Link>
          <div class="row">
            <div className="detail-item"><label>Amount:</label>{payment.amount}</div>
            <div  className="detail-item"><label>Paid By:</label>{payment.paid_by}</div>
            <div  className="detail-item"><label>Description:</label>{payment.description}</div>
          </div>
        </div>
      )) 
    ) : loading ? (
      <Loader />
    ) : (
      <p>No history found!</p>
    )}
    </div>
    </>
  );
};

export default Payments;
