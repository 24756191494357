import React, { useEffect, useState } from "react";
import dataServices from "../../apiServices/data.services";
import { handleValidationError } from "../../components/CommonFunctions";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import Loader from "../../components/Loader/Loader";

const MedicalHistories = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [medical_histories, setMedicalHistories] = useState([]);
  const alert = useAlert();

  const getMedicalHistories = () => {
    setLoading(true);
    dataServices
      .getMdicalHistories(id)
      .then((res) => {
        if (res.status === 200) {
          setMedicalHistories(res.data);
        }
      })
      .catch((err) => {
        alert.error(handleValidationError(err));
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMedicalHistories();
  }, [id]);
  return (
    <>
    <div className="list">
     {medical_histories ? (
      medical_histories.map((medical_history) => (
        <div key={medical_history.id} className="mb-4">
          <div class="row">
            <div className="detail-item"><label>Patient:</label>{medical_history.patient_detail} {medical_history.patient_start_date && 
            medical_history.patient_start_date
            }</div>
            
            <div className="detail-item"><label>Family:</label>{medical_history.family_detail}             {medical_history.family_start_date && 
            medical_history.family_start_date
            }</div>
            

          </div>
        </div>
      )) 
    ) : loading ? (
      <Loader />
    ) : (
      <p>No history found!</p>
    )}
    </div>
    </>
  );
};

export default MedicalHistories;
